import Vue from 'vue'
import Vuex from 'vuex'

import initial from './modules/initial.js'
import user from './modules/user.js'
import recommendStat from './modules/recommendStat.js'
import partnerStat from './modules/partnerStat.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        initial, // 公共信息
        user, // 用户信息
        recommendStat, // 推荐统计
        partnerStat // 合作伙伴统计
    }
})
