<template>
    <el-dialog
        class="anto-dialog"
        :visible.sync="show"
        :show-close="false"
        :width="widthStr"
        :before-close="handleClose">
        <div class="_login">
            <div class="_login-icon">
                <img src="../assets/images/home/login-icon.png" alt="">
            </div>
            <div class="_login-tit">{{ $t('login_title') }}</div>
            <div class="_login-input">
                <div class="label muss">{{ $t('login_mail1') }}</div>
                <div class="value">
                    <el-input class="anto-input" v-model="mail" :placeholder="$t('login_mail2')"></el-input>
                </div>
            </div>
            <div class="_login-input">
                <div class="label muss">{{ $t('login_password1') }}</div>
                <div class="value">
                    <el-input class="anto-input" show-password v-model="password" :placeholder="$t('login_password2')"></el-input>
                </div>
            </div>
            <div class="_login-input">
                <div class="value flex-wrap btns">
                     <div v-if="mail !== '' && password !== ''" class="btn normal" @click="doLogin">{{ $t('login_btn_in') }}</div>
                    <div v-else class="btn disabled">{{ $t('login_btn_in') }}</div>
<!--                    <div class="forget" @click="openForget">{{ $t('login_forget') }}</div>-->
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { login } from '@/utils/api';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            mail: '',
            password: '',
            widthStr: '30%'
        }
    },
    mounted () {
        let width = window.innerWidth
        if (width <= 768) {
            this.widthStr = '90%'
        }
    },
    watch: {
        show: {
            handler(val) {
                if (val) {
                    this.mail = '';
                    this.password = '';
                }
            }
        }
    },
    methods: {
        handleClose () {
            this.$emit('close')
        },
        openForget () {
            this.$emit('open')
        },
        async doLogin() {
            const res = await login({
                username: this.mail,
                password: this.password
            })
            if (res.data.code === 200) {
                this.$store.commit('user/setUser', {
                    isLogin: true,
                    token: res.data.data.token
                })
                this.handleClose()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
._login {
    &-icon {
        padding-bottom: 22px;
        img {
            width: 48px;
            height: 48px;
        }
    }
    &-tit {
        font-weight: 400;
        font-size: 24px;
        color: #fff;
        margin-bottom: 28px;
    }
    &-input {
        margin-bottom: 22px;
        &:last-child {
            margin-bottom: 0;
        }
        .label {
            font-weight: 400;
            font-size: 16px;
            color: #a9aab3;
            margin-bottom: 10px;
            line-height: 24px;
            height: 24px;
        }
        .btn {
            background-color: transparent;
            border: 1px solid #F1BB0C;
            color: #f1bb0c;
            display: block;
            padding: 0 48px;
            line-height: 54px;
            border-radius: 28px;
            transition: all .3s ease;
            font-weight: 700;
            cursor: pointer;
            user-select: none;
            &.normal {
                &:hover {
                    background-color: #f1bb0c;
                    color: #161619;
                }
            }
            &.disabled {
                color: #a9aab3;
                border-color: #a9aab3;
            }
        }
        .btns {
            align-items: center;
        }
        .forget {
            font-size: 16px;
            line-height: 20px;
            color: #f1bb0c;
            transition: all .3s ease;
            margin-left: 25px;
            cursor: pointer;
            &:hover {
                color: rgb(255, 208, 57);
            }
        }
    }
}
@media screen and ( max-width: 768px ) {
    ._login {
        &-input {
            .btn {
                padding: 0 24px;
            }
        }
    }
}
</style>
