import axios from "axios";
import { Message } from 'element-ui'
import router from '@/router/index';
export function request(config){
  const service =axios.create({
    baseURL:'https://owapi.lwdgood.com/api/user', //配置公共接口
    timeout:3000 //配置请求超时时间
  })
  // 请求拦截器
  service.interceptors.request.use(config=>{
    // 语言
    let lang = 'zh-CN';
    const tmpLang = JSON.parse(localStorage.getItem('anto:init'))
    if (tmpLang.language === 'EN') {
      lang = 'en-US';
    }
    // Token
    let token = ''
    const antoUser = localStorage.getItem('anto:user')
    if (antoUser && antoUser !== '') {
      const info = JSON.parse(antoUser)
      token = info.token
    }
    config.headers['accept-language'] = lang
    config.headers['Authorization'] = `${'Bearer ' + token}`
    return config
  },err=>{})

// 响应拦截器
  service.interceptors.response.use(res=>{
    if(res.status===200){
      const data = res.data
      if (data.code === 200){
        if (data.msg !== '') {
          Message({
            type: 'success',
            message: data.msg,
            center: true
          })
        }
      } else if (data.code === 401) {
        localStorage.setItem('anto:user', "")
        if (data.msg !== '') {
          Message({
            type: 'info',
            message: data.msg,
            center: true
          })
        }
        window.location.href="/"
      } else {
        if (data.msg !== '') {
          Message({
            type: 'error',
            message: data.msg,
            center: true
          })
        }
      }
    } else {
      Message({
        message: 'Network error',
        center: true
      })
    }
    return res

  },err=>{
    return err
  })
  return service(config)

}
