<template>
    <div id="app" class="app-container">
        <Header
            :menu="menu"
            :hideuser="hideUser"
            :isDrawer="isDrawer"
            @open="showlogin = true"
            @drawer="getDrawer">
        </Header>
        <router-view/>
        <Footer :menu="menu"></Footer>
        <LoginBox :show="showlogin" @close="showlogin = false" @open="showforget = true"></LoginBox>
        <ForgetBox :show="showforget" @close="closeForget"></ForgetBox>
        <el-drawer
            direction="ltr"
            size="70%"
            class="anto-drawer"
            :show-close="false"
            :visible.sync="isDrawer">
            <div class="drawer-link">
                <span class="drawer-link-item" :class="{ active: menu === 0 }" @click="toMenu('home', 0)">{{ $t('public_menu_home') }}</span>
                <span class="drawer-link-item" :class="{ active: menu === 1 }" @click="toMenu('about', 1)">{{ $t('public_menu_about') }}</span>
                <!-- <span class="drawer-link-item" :class="{ active: menu === 2 }" @click="toMenu('investor', 2)">{{ $t('public_menu_investor') }}</span> -->
                <span class="drawer-link-item" :class="{ active: menu === 3 }" @click="toMenu('partner', 3)">{{ $t('public_menu_partner') }}</span>
                <!-- <span class="drawer-link-item" :class="{ active: menu === 4 }" @click="toMenu('news', 4)">{{ $t('public_menu_news') }}</span> -->
                <span class="drawer-link-item" :class="{ active: menu === 5 }" @click="toMenu('info', 5)">{{ $t('public_menu_contact') }}</span>
                <span class="drawer-link-item" @click="toMenu('privacy', 6)">{{ $t('public_menu_privacy') }}</span>
            </div>
            <div class="drawer-bottom flex-wrap">
                <a href="javascript:;" v-if="!user.isLogin" class="drawer-bottom-item" @click="loginOrReg('login')">{{ $t('public_login') }}</a>
                <a href="javascript:;" v-if="user.isLogin" class="drawer-bottom-item" @click="goAccount()">{{ $t('public_account') }}</a>
                <a href="javascript:;" v-if="user.isLogin" class="drawer-bottom-item reg" @click="logout('reg')">{{ $t('public_loginout') }}</a>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'
import LoginBox from './components/login.vue'
import ForgetBox from './components/forget.vue'
import { mapState } from 'vuex'

export default {
    components: {
        Header,
        Footer,
        LoginBox,
        ForgetBox
    },
    data () {
        return {
            showlogin: false,
            showforget: false,
            menu: 0,
            hideUser: false,
            isDrawer: false, // 是否打开抽屉
            initWalletSuccess: false,
        }
    },
    created () {
        this.$store.dispatch('user/getUser')
        this.getRouterActive(this.$route.name)
        const init = JSON.parse(localStorage.getItem('anto:init'))
        if (!init) {
            const language = this.$store.state.initial.language
            this.$store.commit('initial/setInitial', { language: language })
        }
    },
    computed: {
        ...mapState(['initial', 'user'])
    },
    watch: {
        '$route' (val) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            this.getRouterActive(val.name)
        }
    },
    methods: {
        closeForget () {
            this.showlogin = false
            this.showforget = false
        },
        getRouterActive (name) {
            let navIndex = -1
            const data = {
                0: ['home'],
                1: ['about'],
                2: ['investor'],
                3: ['partner'],
                4: ['news', 'news-id'],
                5: ['info']
            }
            const users = ['user', 'find', 'deposit', 'exchange', 'withdraw', 'mypartner', 'setting']

            this.hideUser = users.includes(name)

            for (let key in data) {
                if (data[key].indexOf(name) !== -1) {
                    navIndex = parseInt(key)
                }
            }
            this.menu = navIndex
        },
        getDrawer (e) {
            this.isDrawer = !this.isDrawer
        },
        loginOrReg (type = 'login') {
            this.isDrawer = false
            if (type === 'login') {
                this.showlogin = true
            } else {
                this.$router.push({
                    name: 'register'
                })
            }
        },
        toMenu (str, index) {
            if (this.menu === index) {
                return false
            }
            this.isDrawer = false
            this.$router.push({
                name: str
            })
        },
        logout () {
            this.$store.commit('user/setUser', {
                isLogin: false,
                token: '',
                address: '',
                positionQuantity: '',
                positionValue: '',
                positionRankings: '0',
                ctQuantity: '',
                rewardRate: 0
            })
        },
        goAccount () {
            this.isDrawer = false
            this.$router.push({
                name: 'user'
            })
        }
    }
}
</script>

<style lang="scss">
@import url('assets/css/base.css');
.drawer {
    &-link {
        &-item {
            display: block;
            line-height: 40px;
            font-weight: 700;
            color: #fff;
            padding-left: 20px;
            &.active {
                color: #F1BB0C;
            }
        }
    }
    &-bottom {
        position: absolute;
        left: 0;
        bottom: 40px;
        right: 0;
        justify-content: space-between;
        padding: 0 20px;
        &-item {
            display: block;
            border: #fff 1px solid;
            border-radius: 24px;
            font-size: 16px;
            color: #161619;
            border-color: #F1BB0C;
            background-color: #F1BB0C;
            line-height: 46px;
            width: 44%;
            text-align: center;
            font-weight: 700;
            &.reg {
                color: #161619;
                border-color: #F1BB0C;
                background-color: #F1BB0C;
            }
        }
    }
}
</style>
