export default {
    namespaced: true,
    state: {
        language: 'CN'
    },
    mutations: {
        setInitial (state, params) {
            for (const [key, value] of Object.entries(params)) {
                if (state.hasOwnProperty(key)) {
                    state[key] = value
                }
            }
            localStorage.setItem('anto:init', JSON.stringify(state))
        }
    },
    actions: {
        getInitial ({state, commit}) {
            const info = localStorage.getItem('anto:init')
            if (info) {
                commit('setInitial', JSON.parse(info))
            }
        }
    }
}
