<template>
    <div class="_header wrapper flex-wrap" v-if="!hideuser">
        <div class="_header-open" :class="{ active: isDrawer }" @click="openDrawer">
            <span></span>
        </div>
        <div class="_header-logo">
            <img class="img" src="../assets/images/logo.png" alt="">
        </div>
        <div class="_header-menu flex-wrap">
            <router-link class="item" :class="{ active: menu === 0 }" to="/">{{ $t('public_menu_home') }}</router-link>
            <router-link class="item" :class="{ active: menu === 1 }" to="/about">{{ $t('public_menu_about') }}</router-link>
            <!-- <router-link class="item" :class="{ active: menu === 2 }" to="/investor">{{ $t('public_menu_investor') }}</router-link> -->
            <router-link class="item" :class="{ active: menu === 3 }" to="/partner">{{ $t('public_menu_partner') }}</router-link>
            <!-- <router-link class="item" :class="{ active: menu === 4 }" to="/news">{{ $t('public_menu_news') }}</router-link> -->
            <router-link class="item" :class="{ active: menu === 5 }" to="/info">{{ $t('public_menu_contact') }}</router-link>
        </div>
        <div class="_header-lang">
            <el-popover
                placement="bottom"
                width="216"
                trigger="click"
                v-model="isLanguage">
                <div class="_header-lang-btn flex-wrap" slot="reference">
                    <span class="gq guoqi" :class="initial.language"></span>
                    <span class="txt">{{ initial.language }}</span>
                    <span class="icon"></span>
                </div>
                <div class="_header-lang-list flex-wrap">
                    <div
                        class="item flex-wrap"
                        v-for="(item, index) in language"
                        :key="index"
                        :class="{ active: initial.language === item }"
                        @click="changeLanguage(item)">
                        <span class="gq guoqi" :class="item"></span>
                        <span class="txt">{{ item }}</span>
                    </div>
                </div>
            </el-popover>
        </div>
        <div class="_header-btn flex-wrap" v-if="user.isLogin">
            <router-link class="link reg" to="/user">{{ $t('public_account') }}</router-link>
            <span class="link login" @click="logout">{{ $t('public_loginout') }}</span>
        </div>
        <div class="_header-btn flex-wrap" v-else>
            <span class="link login" @click="openLogin">{{ $t('public_login') }}</span>
            <!-- <router-link class="link reg" to="/register">{{ $t('public_register') }}</router-link> -->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import language from '@/utils/language'

export default {
    props: {
        menu: {
            type: Number,
            default: 0
        },
        hideuser: {
            type: Boolean,
            default: false
        },
        isDrawer: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            language: language,
            isLanguage: false
        }
    },
    computed: {
        ...mapState(['initial', 'user'])
    },
    methods: {
        openLogin () {
            this.$emit('open')
        },
        changeLanguage (item) {
            this.$store.commit('initial/setInitial', {
                language: item
            })
            this.$i18n.locale = item
            this.isLanguage = false
        },
        openDrawer () {
            this.$emit('drawer')
        },
        logout() {
            this.$store.commit('user/setUser', {
                isLogin: false,
                token: '',
                address: '',
                positionQuantity: '',
                positionValue: '',
                positionRankings: '0',
                ctQuantity: ''
            })
        }
    }
}
</script>

<style lang="scss" scoped>
._header {
    padding-top: 48px;
    padding-bottom: 48px;
    align-items: center;
    &-open {
        width: 20px;
        height: 16px;
        margin-right: 20px;
        position: relative;
        display: none;
        &::before {
            transition: all .3s ease;
            width: 100%;
            height: 2px;
            background-color: #fff;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
        }
        &::after {
            width: 100%;
            height: 2px;
            background-color: #fff;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            transition: all .3s ease;
        }
        &.active::before {
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(-45deg);
            bottom: auto;
        }
        &.active::after {
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
        }
        &.active {
            span {
                display: none;
            }
        }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            height: 2px;
            background-color: #fff;
        }
    }
    &-logo {
        .img {
            width: 198px;
            height: 54px;
            object-fit: cover;
        }
    }
    &-menu {
        flex: 1;
        justify-content: flex-end;
        margin-right: 15px;
        .item {
            display: block;
            line-height: 16px;
            font-weight: 700;
            color: #fff;
            margin-left: 15px;
            transition: all .3s ease;
            &:first-child {
                margin-left: 0;
            }
            &:hover {
                color: #f1bb0c;
            }
            &.active {
                color: #f1bb0c;
            }
        }
    }
    &-lang {
        &-btn {
            cursor: pointer;
            align-items: center;
            .gq {
                width: 24px;
                height: 24px;
                background-size: 48px 24px;
                border-radius: 50%;
                background-position: center;
            }
            .txt {
                font-size: 14px;
                line-height: 24px;
                display: block;
                font-weight: 700;
                color: #f1bb0c;
                padding: 0 9px 0 12px;
            }
            .icon {
                width: 16px;
                height: 16px;
                background-image: url('../assets/images/lang/arrow-down.png');
                background-size: cover;
                display: block;
            }
        }
        &-list {
            flex-wrap: wrap;
            .item {
                cursor: pointer;
                align-items: center;
                width: 50%;
                height: 48px;
                border: #1e1f25 1px solid;
                border-radius: 24px;
                padding-left: 12px;
                &.active {
                    border-color: #fff;
                }
                &:hover {
                    .txt {
                        color: #f1bb0c;
                    }
                }
            }
            .txt {
                font-size: 14px;
                line-height: 24px;
                display: block;
                font-weight: 700;
                color: #fff;
                padding: 0 9px 0 12px;
                transition: all .3s ease;
            }
            .gq {
                width: 24px;
                height: 24px;
                background-size: 48px 24px;
                border-radius: 50%;
                background-position: center;
            }
        }
    }
    &-btn {
        .link {
            padding: 0 22px;
            line-height: 46px;
            display: block;
            border: #fff 1px solid;
            border-radius: 100px;
            font-weight: 700;
            color: #fff;
            margin-left: 15px;
            transition: all .3s ease;
            cursor: pointer;
            &.login {
                background-color: #f1bb0c;
                border-color: #f1bb0c;
                color: #333;
                &:hover {
                    background-color: rgb(255, 208, 57);
                    border-color: rgb(255, 208, 57);
                }
            }
            &.reg {
                background-color: #f1bb0c;
                border-color: #f1bb0c;
                color: #333;
                &:hover {
                    background-color: rgb(255, 208, 57);
                    border-color: rgb(255, 208, 57);
                }
            }
        }
    }
}
/* 小于768 */
@media screen and ( max-width: 768px ) {
    ._header {
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #111112;
        background-image: linear-gradient(to right, #070a2a, #111112);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 9;
        &-open {
            display: block;
        }
        &-logo {
            flex: 1;
            .img {
                height: 32px;
                width: auto;
            }
        }
        &-menu {
            display: none;
        }
        &-btn {
            display: none;
        }
    }
}
</style>
