import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/investor',
        name: 'investor',
        component: () => import('../views/InvestorView.vue')
    },
    {
        path: '/partner',
        name: 'partner',
        component: () => import('../views/PartnerView.vue'),
        mate: {
            requireAuth: true
        }
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/news/index.vue')
    },
    {
        path: '/news/:id',
        name: 'news-id',
        component: () => import('../views/news/_id.vue')
    },
    {
        path: '/info',
        name: 'info',
        component: () => import('../views/InfoView.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/Privacy.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/User.vue'),
        mate: {
            requireAuth: true
        }
    },
    // {
    //     path: '/find',
    //     name: 'find',
    //     component: () => import('../views/user/Find.vue')
    // },
    // {
    //     path: '/deposit',
    //     name: 'deposit',
    //     component: () => import('../views/user/Deposit.vue')
    // },
    // {
    //     path: '/exchange',
    //     name: 'exchange',
    //     component: () => import('../views/user/Exchange.vue')
    // },
    // {
    //     path: '/withdraw',
    //     name: 'withdraw',
    //     component: () => import('../views/user/Withdraw.vue')
    // },
    {
        path: '/mypartner',
        name: 'mypartner',
        component: () => import('../views/user/Partner.vue')
    },
    // {
    //     path: '/setting',
    //     name: 'setting',
    //     component: () => import('../views/user/Setting.vue')
    // },
    {
        path: '/404',
        name: 'notfind',
        component: () => import('../views/Error.vue')
    },
    {
        path: '*',
        redirect: '/404'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        const info = JSON.parse(localStorage.getItem('anto:user'))
        if (info.token) {
            next()
        } else {
            next('/')
        }
    } else {
        next()
    }
})

export default router
