<template>
    <div class="_footer wrapper">
        <div class="_footer-top flex-wrap">
            <div class="logo">
                <img class="img" src="../assets/images/logo.png" alt="">
            </div>
            <div class="menu flex-wrap">
                <router-link class="item" :class="{ active: menu === 0 }" to="/">{{ $t('public_menu_home') }}</router-link>
                <router-link class="item" :class="{ active: menu === 1 }" to="/about">{{ $t('public_menu_about') }}</router-link>
                <!-- <router-link class="item" :class="{ active: menu === 2 }" to="/investor">{{ $t('public_menu_investor') }}</router-link> -->
                <router-link class="item" :class="{ active: menu === 3 }" to="/partner">{{ $t('public_menu_partner') }}</router-link>
                <!-- <router-link class="item" :class="{ active: menu === 4 }" to="/news">{{ $t('public_menu_news') }}</router-link> -->
                <router-link class="item" :class="{ active: menu === 5 }" to="/info">{{ $t('public_menu_contact') }}</router-link>
                <router-link class="item" to="/privacy">{{ $t('public_menu_privacy') }}</router-link>
            </div>
        </div>
        <div class="_footer-info flex-wrap">
            <div class="info">{{ $t('public_register_tip') }}</div>
            <div class="language">{{ $t('public_language') }}</div>
            <!-- <div class="lang flex-wrap">
                <span class="gq guoqi" :class="initial.language"></span>
                <span class="txt">{{ initial.language }}</span>
                <span class="icon"></span>
            </div> -->
            <div class="_footer-lang">
                <el-popover
                    placement="bottom"
                    width="216"
                    trigger="click"
                    v-model="isLanguage">
                    <div class="_footer-lang-btn flex-wrap" slot="reference">
                        <span class="gq guoqi" :class="initial.language"></span>
                        <span class="txt">{{ initial.language }}</span>
                        <span class="icon"></span>
                    </div>
                    <div class="_footer-lang-list flex-wrap">
                        <div
                            class="item flex-wrap"
                            v-for="(item, index) in language"
                            :key="index"
                            :class="{ active: initial.language === item }"
                            @click="changeLanguage(item)">
                            <span class="gq guoqi" :class="item"></span>
                            <span class="txt">{{ item }}</span>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>
        <div class="_footer-chat flex-wrap">
            <div class="_footer-chat-item">
                <a target="_blank" href="https://x.com/lwthedefi">
                    <svg>
                        <use xlink:href="../assets/images/sprite.svg#tw"></use>
                    </svg>
                </a>
            </div>
            <div class="_footer-chat-item">
                <a target="_blank" href="https://www.facebook.com/lwdefi">
                    <svg>
                        <use xlink:href="../assets/images/sprite.svg#fb"></use>
                    </svg>
                </a>
            </div>
            <div class="_footer-chat-item">
                <a target="_blank" href="https://t.me/LWDEFI">
                    <svg>
                        <use xlink:href="../assets/images/sprite.svg#tg"></use>
                    </svg>
                </a>
            </div>
            <div class="_footer-chat-item">
                <a target="_blank" href="https://www.youtube.com/@LWDEFI">
                    <svg>
                        <use xlink:href="../assets/images/sprite.svg#yt"></use>
                    </svg>
                </a>
            </div>
        </div>
        <div class="_footer-tips">
            <p>{{ $t('public_footer_tip1') }}</p>
            <p>{{ $t('public_footer_tip2') }}</p>
        </div>
        <div class="_footer-copy flex-wrap">
            <span>© 2024</span>
            <span class="tit">Linking The World Defi</span>
            <span>{{ $t('public_copyright') }}</span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import language from '@/utils/language'

export default {
    props: {
        menu: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            language: language,
            isLanguage: false
        }
    },
    computed: {
        ...mapState(['initial'])
    },
    methods: {
        changeLanguage (item) {
            this.$store.commit('initial/setInitial', {
                language: item
            })
            this.$i18n.locale = item
            this.isLanguage = false
        }
    }
}
</script>

<style lang="scss" scoped>
._footer {
    &-top {
        align-items: center;
        .img {
            width: 198px;
            height: 54px;
            object-fit: cover;
        }
        .menu {
            flex: 1;
            justify-content: flex-end;
        }
        .item {
            display: block;
            line-height: 16px;
            font-weight: 700;
            color: #fff;
            margin-left: 15px;
            transition: all .3s ease;
            &:first-child {
                margin-left: 0;
            }
            &:hover {
                color: #f1bb0c;
            }
            &.active {
                color: #f1bb0c;
            }
        }
    }
    &-info {
        align-items: center;
        margin: 22px 0 24px;
        .info {
            flex: 1;
            font-size: 16px;
            color: #A9AAB3;
            line-height: 24px;
        }
        .language {
            font-size: 16px;
            color: #A9AAB3;
            line-height: 24px;
            margin-right: 10px;
        }
    }
    &-chat {
        flex-direction: row;
        &-item {
            margin-right: 20px;
            a {
                width: 42px;
                height: 42px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: 1px solid #4D4E53;
                transition: all .3s ease;
                text-decoration: none;
                cursor: pointer;
                &:hover {
                    border-color: #f1bb0c;
                }
            }
            svg {
                width: 18px;
                height: 18px;
                fill: #fff;
            }
        }
    }
    &-tips {
        padding-top: 40px;
        p {
            line-height: 1.57;
            margin-bottom: 24px;
            color: #a9aab3;
        }
    }
    &-copy {
        height: 50px;
        align-items: center;
        span {
            display: block;
            color: #a9aab3;
            font-size: 16px;
            line-height: 28px;
            &.tit {
                color: #fff;
                padding: 0 4px;
            }
        }
    }
    &-lang {
        &-btn {
            cursor: pointer;
            align-items: center;
            .gq {
                width: 24px;
                height: 24px;
                background-size: 48px 24px;
                border-radius: 50%;
                background-position: center;
            }
            .txt {
                font-size: 14px;
                line-height: 24px;
                display: block;
                font-weight: 700;
                color: #f1bb0c;
                padding: 0 9px 0 12px;
            }
            .icon {
                width: 16px;
                height: 16px;
                background-image: url('../assets/images/lang/arrow-down.png');
                background-size: cover;
                display: block;
            }
        }
        &-list {
            flex-wrap: wrap;
            .item {
                cursor: pointer;
                align-items: center;
                width: 50%;
                height: 48px;
                border: #1e1f25 1px solid;
                border-radius: 24px;
                padding-left: 12px;
                &.active {
                    border-color: #fff;
                }
                &:hover {
                    .txt {
                        color: #f1bb0c;
                    }
                }
            }
            .txt {
                font-size: 14px;
                line-height: 24px;
                display: block;
                font-weight: 700;
                color: #fff;
                padding: 0 9px 0 12px;
                transition: all .3s ease;
            }
            .gq {
                width: 24px;
                height: 24px;
                background-size: 48px 24px;
                border-radius: 50%;
                background-position: center;
            }
        }
    }
}
@media screen and ( max-width: 768px ) {
    ._footer {
        &-top {
            .menu {
                display: none;
            }
        }
        &-web {
            flex-direction: column;
            align-items: flex-start;
            .btn {
                padding-left: 0;
            }
            .link {
                margin-left: 0;
                line-height: 34px;
                margin-top: 10px;
            }
        }
    }
}
</style>
