import fetch from '@/utils/request'

/* 会员登录 */
export function login(params) {
  return fetch.post({
    url: '/auth/login',
    data: params
  })
}

/* 会员详情 */
export function profile() {
  return fetch.get({
    url: '/auth/profile',
  })
}

/* 推荐统计信息 */
export function recommendStat() {
  return fetch.get({
    url: '/stat/recommend',
  })
}

/* 合作伙伴统计信息 */
export function partnerStat() {
  return fetch.get({
    url: '/stat/partner',
  })
}

/* 生成地址掩码 */
export function generateAddressMask(address) {
  const prefix = address.substr(0, 8)
  const suffix = address.substr(38, address.length - 1)
  return `${prefix}.....${suffix}`
}
