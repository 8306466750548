<template>
    <el-dialog
        class="anto-dialog"
        :visible.sync="show"
        :show-close="false"
        width="30%"
        :before-close="handleClose">
        <div class="_forget">
            <div class="_forget-icon">
                <img src="../assets/images/home/password-icon.png" alt="">
            </div>
            <div class="_forget-tit">{{ $t('login_forget') }}</div>
            <div class="_forget-desc">
                <p>{{ $t('login_forget_txt1') }}</p>
                <p v-html="$t('login_forget_txt2')"></p>
            </div>
            <div class="_forget-btn flex-wrap">
                <div class="btn" @click="handleClose">{{ $t('login_btn_out') }}</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            mail: '',
            password: ''
        }
    },
    methods: {
        handleClose () {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
._forget {
    &-icon {
        padding-bottom: 22px;
        img {
            width: 48px;
            height: 48px;
        }
    }
    &-tit {
        font-weight: 400;
        font-size: 24px;
        color: #fff;
        margin-bottom: 28px;
    }
    &-desc {
        p {
            font-size: 16px;
            color: #bbb;
            line-height: 24px;
        }
    }
    &-btn {
        padding-top: 30px;
        .btn {
            padding: 0 22px;
            line-height: 46px;
            display: block;
            border: #f1bb0c 1px solid;
            border-radius: 100px;
            font-weight: 700;
            transition: all .3s ease;
            cursor: pointer;
            background-color: #f1bb0c;
            color: #333;
            &:hover {
                background-color: rgb(255, 208, 57);
                border-color: rgb(255, 208, 57);
            }
        }
    }
}
</style>
