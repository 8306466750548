export default {
  namespaced: true,
  state: {
    address: "",
    performance: "",
    user_effect: "",
    reward: "",
    member_list: []
  },
  mutations: {
    setPartnerStatStat (state, params) {
      for (const [key, value] of Object.entries(params)) {
        if (state.hasOwnProperty(key)) {
          state[key] = value
        }
      }
      localStorage.setItem('anto:partnerStat', JSON.stringify(state))
    }
  },
  actions: {
    getPartnerStat ({state, commit}) {
      const info = localStorage.getItem('anto:partnerStat')
      if (info) {
        commit('setUser', JSON.parse(info))
      }
    }
  }
}
