import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const info = localStorage.getItem('anto:init')
if (info) {
    store.dispatch('initial/getInitial')
}

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: store.state.initial.language,
    messages: {
        'CN': require('./lang/CN.json'),
        'EN': require('./lang/EN.json'),
        'AE': require('./lang/AE.json')
    },
    silentTranslationWarn: true
})

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
