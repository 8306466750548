export default {
    namespaced: true,
    state: {
        isLogin: false,
        token: '',
        address: '',
        positionQuantity: '',
        positionValue: '',
        positionRankings: '0',
        ctQuantity: '',
        rewardRate: 0,
    },
    mutations: {
        setUser (state, params) {
            for (const [key, value] of Object.entries(params)) {
                if (state.hasOwnProperty(key)) {
                    state[key] = value
                }
            }
            localStorage.setItem('anto:user', JSON.stringify(state))
        }
    },
    actions: {
        getUser ({state, commit}) {
            const info = localStorage.getItem('anto:user')
            if (info) {
                commit('setUser', JSON.parse(info))
            }
        }
    }
}
